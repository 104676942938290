exports.components = {
  "component---src-pages-connectagon-tsx": () => import("./../../../src/pages/connectagon.tsx" /* webpackChunkName: "component---src-pages-connectagon-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-job-tsx": () => import("./../../../src/templates/job.tsx" /* webpackChunkName: "component---src-templates-job-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-produkt-tsx": () => import("./../../../src/templates/produkt.tsx" /* webpackChunkName: "component---src-templates-produkt-tsx" */),
  "component---src-templates-unternehmen-tsx": () => import("./../../../src/templates/unternehmen.tsx" /* webpackChunkName: "component---src-templates-unternehmen-tsx" */),
  "component---src-templates-updates-tsx": () => import("./../../../src/templates/updates.tsx" /* webpackChunkName: "component---src-templates-updates-tsx" */)
}

